import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.hide();
    }, 8000);
  }

  hide() {
    this.element.style.display = "none";
  }
}
