import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['addBtn', 'removeBtn'];

  connect() {
    const offerId = this.element.dataset.offerId;
    this.getOfferIds().includes(offerId) ? this.showRemoveBtn() : this.showAddBtn();
  }

  get searchOffersController() {
    const element = document.querySelector("[data-controller='programs--search-offers']");
    if (!element) return;

    return this.application.getControllerForElementAndIdentifier(element, "programs--search-offers");
  }

  getOfferIds() {
    let ids = this.getCookie("offers");
    ids ? ids = ids.split(",") : ids = [];
    return ids;
  }

  getCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  }

  addOffer(event) {
    let ids = this.getOfferIds();
    ids.push(event.target.dataset.offerId);
    this.setCookie("offers", [...new Set(ids)].join(","));
    this.showRemoveBtn();
    this.searchOffersController.refreshBtn()
  }

  removeOffer(event) {
    let ids = this.getOfferIds();
    ids.splice(ids.indexOf(event.target.dataset.offerId), 1);
    this.setCookie("offers", [...new Set(ids)].join(","));
    this.showAddBtn();
    this.searchOffersController.refreshBtn();
  }

  showAddBtn() {
    this.addBtnTarget.classList.remove('hidden');
    this.removeBtnTarget.classList.add('hidden');
  }

  showRemoveBtn() {
    this.addBtnTarget.classList.add('hidden');
    this.removeBtnTarget.classList.remove('hidden');
  }

  setCookie(name, value) {
    document.cookie = name + '=' + value + '; Path=/; Expires=Fri, 31 Dec 9999 23:59:59 GMT;';
  }
}
