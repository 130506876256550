import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tabLink", "tabPane"];

  correspondingTabPane(tabLink) {
    const id = tabLink.dataset.targetPaneId;
    return this.tabPaneTargets.find(tabPane => tabPane.id === id);
  }

  show() {
    event.preventDefault();
    this.activate(event.currentTarget);
  }

  activate(tabLink) {
    this.tabLinkTargets.forEach(tabLink => {
      tabLink.classList.remove("active");
    });
    this.tabPaneTargets.forEach(tabPane => {
      tabPane.classList.add("hidden");
    });
    tabLink.classList.add("active");
    this.correspondingTabPane(tabLink).classList.remove("hidden");
  }
}