import { Controller } from "stimulus";
import { useWindowResize } from 'stimulus-use'

export default class extends Controller {
  static targets = ["grid", "showMoreLink"];

  connect() {
    this.step = 1;
    this.resizeGridAndContainer();
    useWindowResize(this);
  }

  windowResize({ width, height, event }) {
    this.gridTarget.style.height = 'auto';
    this.element.style.height = 'auto';
    this.resizeGridAndContainer();
  }

  resizeGridAndContainer() {
    this.setGridHeight();
    this.setContainerHeight();
  }

  setGridHeight() {
    if (window.screen.width >= 640) this.gridTarget.style.height = this.gridTarget.offsetHeight / 2.8 + "px";
  }

  setContainerHeight() {
    const targetHeight = (this.gridTarget.offsetHeight / 3) * this.step;
    this.element.style.height = targetHeight + "px";
  }

  showMore() {
    event.preventDefault();
    this.step++;
    this.setContainerHeight();
    if (this.step === 3) this.showMoreLinkTarget.style.display = "none";
  }
}