import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["panel", "overlay", "panelContainer"];

  open() {
    this.overlayTarget.classList.remove("hidden");
    this.overlayTarget.classList.add("fixed");
    this.panelContainerTarget.classList.remove("hidden");
    this.panelContainerTarget.classList.add("fixed");
    this.panelTarget.classList.remove("translate-x-full");
    this.panelTarget.classList.add("translate-x-0");
  }

  close() {
    this.overlayTarget.classList.add("hidden");
    this.overlayTarget.classList.remove("fixed");
    this.panelContainerTarget.classList.add("hidden");
    this.panelContainerTarget.classList.remove("fixed")
    this.panelTarget.classList.add("translate-x-full");
    this.panelTarget.classList.remove("translate-x-0");
  }
}