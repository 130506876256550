import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal']

  handleKeyDown = (e) => {
    // Esc
    if (e.keyCode === 27) this.close();
  }

  connect() {
    document.addEventListener('keydown', this.handleKeyDown);
    this.openByDefault();
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  open() {
    this.modalTarget.classList.add('modal-visible');
    if (this.element.dataset.offer !== undefined) {
      if (this.tabsBarElement) { this.tabsBarElement.style = 'z-index: 0' };
      if (this.headerElement) { this.headerElement.style = 'z-index: 0' };
    }
  }

  close(e) {
    if (!e) return;
    if (!e.target.classList.contains('modal-overlay') && !e.target.classList.contains('modal-close') && !e.currentTarget.classList.contains('modal-close')) return;
    setTimeout(() => {
      this.modalTarget.classList.remove('modal-visible');
    }, 0);
    if (this.element.dataset.offer !== undefined) {
      if (this.tabsBarElement) { this.tabsBarElement.style = 'z-index: 20' };
      if (this.headerElement) { this.headerElement.style = 'z-index: 100' };
    }
  }

  openByDefault() {
    const opened_at_connect = new URLSearchParams(window.location.search).get('open_modal') === 'true'
    if (this.element.querySelector('[data-open-modal-at-connect]') && opened_at_connect) {
      this.open();
    }
  }

  get tabsBarElement() {
    return document.querySelector('[data-target="tabs.tabsBar"]');
  }

  get headerElement() {
    return document.querySelector('[data-controller="navbar"]');
  }
}
