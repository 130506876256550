import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submitBtn", "emailInput"]

  connect() {
    this.action = this.element.action;
    this.classList = Array.from(this.submitBtnTarget.classList);
    this.validate();
    console.log(this.action)
  }

  validate(event) {
    if (event) {
      this.#emailIsValid(event.currentTarget.value) ? this.#activateForm() : this.#disactivateForm()
    } else if (this.hasEmailInputTarget) {
      this.#emailIsValid(this.emailInputTarget.value) ? this.#activateForm() : this.#disactivateForm()
    } else {
      this.#disactivateForm();
    }
  }

  get #invalidEmailClassList() {
    return this.classList.join(' ');
  }

  get #validEmailClassList() {
    if (this.action.includes('team_member')) {
      const classList = this.classList.map(el => el.replace(/gray.*/, 'success'))
      classList.push('cursor-pointer');
      return classList.join(' ');
    }
    if (this.action.includes('add_email_to_alert_list')) {
      var classList = this.classList
      classList = classList.filter(el => !['bg-gray-200', 'hover:bg-gray-200', 'hover:text-black', 'cursor-default'].includes(el));
      classList.push('bg-warning')
      return classList.join(' ');
    }
  }

  #disactivateForm() {
    this.element.removeAttribute("action");
    this.submitBtnTarget.disabled = true;
    this.submitBtnTarget.classList = this.#invalidEmailClassList;
  }

  #activateForm() {
    this.element.setAttribute("action", this.action);
    this.submitBtnTarget.disabled = false;
    this.submitBtnTarget.classList = this.#validEmailClassList;
  }

  #emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

}
