import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["actions"]

  connect() {
    this.bgClass = Array.from(this.element.classList).find(c => c.match(/bg-gray/))
    this.destroyConfirmText = this.element.dataset.destroyConfirmText;
  }

  call(event) {
    event.preventDefault();
    event.stopPropagation();
    const currentTarget = event.currentTarget;
    const method = event.currentTarget.dataset.method;
    if (method == "delete" && !confirm(this.destroyConfirmText)) return;
    fetch(event.currentTarget.href, {
      method: event.currentTarget.dataset.method,
      headers: {
        'X-CSRF-Token': csrfToken(),
        'accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then((data) => {
        if (method == "delete") {
          if (!data.success) return;
          this.element.classList.add('line-through');
          this.element.classList.add('bg-gray-200');
          this.actionsTarget.remove();
        } else {
          if (data.html) {
            this.element.outerHTML = data.html;
            this.bgClass ? this.element.classList.add(this.bgClass) : this.element.classList.remove(this.bgClass)
          }
          if (data.resent) {
            const text = currentTarget.innerText;
            currentTarget.innerText = 'Done!';
            setTimeout(() => {
              currentTarget.innerText = text;
            }, 2000);
          }
        }
      });
  }
}
