import { Controller } from "stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    new TomSelect(this.selectTarget, {
      maxItems: 3,
      plugins: ['remove_button'],
      render: {
        item: function (data, escape) {
          return `<div class="item flex items-center px-2 py-1 bg-gray-100 rounded mr-1">${escape(data.text)}</div>`;
        },
        option: function (data, escape) {
          return `<div class="option py-2 px-3 cursor-pointer hover:bg-gray-100">${escape(data.text)}</div>`;
        },
      },
      dropdownClass: 'ts-dropdown border-none rounded shadow-none',
      optionClass: 'ts-option',
      itemClass: 'ts-item',
      controlClass: 'ts-control rounded h-10 px-4 border-none',
      loadingClass: 'ts-loading',
      dropdownContentClass: 'ts-dropdown-content',
      removeButtonClass: 'ts-remove ml-1 text-gray-500 hover:text-gray-700',
    });
  }
}