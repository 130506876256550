import { Controller } from "stimulus";

export default class extends Controller {

  change(event) {
    if (event.target.value === "") {
      return;
    }

    event.preventDefault();
    window.location = event.target.value + "?open_modal=true";
  }
}