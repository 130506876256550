import { Controller } from "stimulus";

export default class extends Controller {
    open() {
      this.element.classList.remove("hidden");
    }

    close() {
        this.element.classList.add("hidden");
    }
}
