import { Controller } from "stimulus";

export default class extends Controller {
  showModal(e) {
    e.preventDefault();
    e.stopPropagation();
    const modal = document.querySelector(".c-modal-unlock");

    modal.classList.add("c-modal--visible");
  }
}
