import { Controller } from "stimulus";
// import Swiper JS
import Swiper, { Mousewheel, Navigation, Pagination } from "swiper";

// import Swiper styles
import "swiper/swiper-bundle.min.css"

Swiper.use([Mousewheel, Navigation, Pagination]);
export default class extends Controller {
  connect() {
    this.verticalSwiper();
    this.horizontalSwiper();
    this.gridSwiper();
    this.carouselSwiper();
    // this.paginationSwiper();
  }

  verticalSwiper() {
    const swiper = new Swiper(".vertical-swiper", {
      spaceBetween: 30,
      direction: "vertical",
      mousewheel: true
    });
  }

  horizontalSwiper() {
    const swiper = new Swiper(".horizontal-swiper", {
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }

  gridSwiper() {
    const swiper = new Swiper(".grid-swiper", {
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is >= 768px (md)
        768: {
          slidesPerView: 2,
        },
        // when window width is >= 1024px (lg)
        1024: {
          slidesPerView: 3,
        }
      }
    });
  }

  carouselSwiper() {
    const swiper = new Swiper(".carousel-swiper", {
      slidesPerView: 'auto',
      rewind: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        768: {
          allowTouchMove: false
        }
      }
    });
  }

  // paginationSwiper() {
  //   const swiper = new Swiper(".pagination-swiper", {
  //     slidesPerView: 1,
  //     pagination: {
  //       el: ".swiper-pagination",
  //       type: "bullets"
  //     }
  //   });
  // }
}
