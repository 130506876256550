import { Controller } from "stimulus";
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  static targets = ["card", "bar"];

  connect() {
    useIntersection(this, {
      element: this.cardTarget,
      rootMargin: "-50% 0% 0% 0%"
    });

    const bar = this.barTarget;
    document.onscroll = this.hideAtBottom(bar);
  }

  hideAtBottom(el) {
    return () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const bodyHeight = document.body.clientHeight;

      if (scrollPosition < bodyHeight - 500) return;

      if (scrollPosition > bodyHeight - 250) {
        return el.style.zIndex = -10;
      }

      if (scrollPosition > bodyHeight - 500 && scrollPosition < bodyHeight - 250) {
        return el.style.zIndex = 10;
      }
    }
  }

  disappear() {
    this.barTarget.style.zIndex = 10;
  }

  appear() {
    this.barTarget.style.zIndex = -10;
  }
}