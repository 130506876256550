import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "mobileContainer", "navburger" ]

  toggle () {
    if (this.mobileContainerTarget.classList.contains("unfolded")) {
      this.fold()
    }
    else {
      this.unfold()
    }
  }

  fold () {
    this.mobileContainerTarget.classList.remove("unfolded")
    this.navburgerTarget.classList.remove("unfolded")
  }

  unfold () {
    this.mobileContainerTarget.classList.add("unfolded")
    this.navburgerTarget.classList.add("unfolded")
  }
}
