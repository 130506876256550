import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["offerAlertSubscribed", "emailForm", "noOfferParagraph"]

  get mobileOffersSection() {
    return document.querySelector('[data-mobile-offers-section]');
  }

  submit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget)
    // 👇 no need to wait for server answer
    fetch(event.currentTarget.action, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('[name=csrf-token]').content,
        'Accept': 'application/json'
      },
      body: formData
    })
    this.#showOfferAlertSubscribed();
    this.mobileOffersSection?.classList.add('hidden');
  }

  #showOfferAlertSubscribed() {
    this.offerAlertSubscribedTarget.classList.remove('hidden');
    this.emailFormTarget.classList.add('hidden');
    this.noOfferParagraphTarget.classList.add('hidden');
  }
}