import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['option']

  open() {
    this.optionTargets.forEach(element => {
      element.classList.remove("opacity-0")
      element.classList.remove("theme-contrast")
      element.classList.add("theme-darker")
    });
  }

  close() {
    this.optionTargets.forEach(element => {
      element.classList.add("opacity-0")
      element.classList.add("theme-contrast")
      element.classList.remove("theme-darker")
    });
  }
}
