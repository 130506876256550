import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ['input', 'container', 'form', 'noDealBtn', 'oneDealBtn', 'dealsBtn', 'dealsCounter'];

  connect() {
    this.refreshBtn();
    const url = this.formTarget.action;
    if (this.getOfferIds().length > 0) {
      const url = this.formTarget.action + '?filters[ids]=' + this.getCookie("offers");
      this.fetchOffers(url);
    } else {
      this.fetchOffers(url);
    }
  }

  getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  }

  getOfferIds() {
    let ids = this.getCookie("offers");
    ids ? ids = ids.split(",") : ids = [];
    return ids;
  }

  deleteCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  submit(event) {
    event.preventDefault();
    event.stopPropagation();
    const query = this.inputTarget.value;
    const url = this.formTarget.action + '?filters[query]=' + query;

    if (query.length === 0 && this.getOfferIds().length > 0) {
      const url = this.formTarget.action + '?filters[ids]=' + this.getCookie("offers");
      this.fetchOffers(url);
      return;
    }

    if (query.length < 3 && query.length !== 0) {
      return;
    }

    this.fetchOffers(url);
  }

  fetchOffers(url) {
    fetch(url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': csrfToken(),
        'accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        this.containerTarget.innerHTML = '';
        data.offers.forEach((el) => {
          this.containerTarget.insertAdjacentHTML('beforeend', el);
        });
      });
  }

  createActivations(event) {
    const url = event.currentTarget.dataset.url;
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken(),
        'accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.deleteCookie("offers");
          window.location.reload();
        }
      });
  }

  refreshBtn() {
    const selectedOffersCount = this.getOfferIds().length;
    if (selectedOffersCount === 0) {
      this.noDealBtnTarget.classList.remove('hidden');
      this.oneDealBtnTarget.classList.add('hidden');
      this.dealsBtnTarget.classList.add('hidden');
    } else if (selectedOffersCount === 1) {
      this.noDealBtnTarget.classList.add('hidden');
      this.oneDealBtnTarget.classList.remove('hidden');
      this.dealsBtnTarget.classList.add('hidden');
    } else {
      this.noDealBtnTarget.classList.add('hidden');
      this.oneDealBtnTarget.classList.add('hidden');
      this.dealsBtnTarget.classList.remove('hidden');
      this.dealsCounterTarget.innerHTML = selectedOffersCount;
    }
  }
}
