import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["show", "edit"];

  displayEdit() {
    this.editTarget.classList.remove("hidden");
    this.showTarget.classList.add("hidden");
  }

  undisplayEdit() {
    this.showTarget.classList.remove("hidden");
    this.editTarget.classList.add("hidden");
  }
}