import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["link"]

  handleClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const link = event.currentTarget;
    const itemName = link.dataset.itemName;
    const itemListName = link.dataset.itemListName;
    const itemBrand = link.dataset.itemBrand;
    const itemCategory = link.dataset.itemCategory;
    const itemCategory2 = link.dataset.itemCategory2;
    const price = link.dataset.price;
    const offerSlug = link.dataset.offerSlug;
    const offerId = link.dataset.offerId;
    const stripePlanId = link.dataset.stripePlanId;

    this.getGAClientAndSessionId().then((ids) => {
      fetch('/checkouts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify({
          item_name: itemName,
          item_list_name: itemListName,
          item_brand: itemBrand,
          item_category: itemCategory,
          item_category2: itemCategory2,
          price: price,
          offer_slug: offerSlug,
          offer_id: offerId,
          client_id: ids.client_id,
          session_id: ids.session_id
        })
      }).then(response => response.json())
        .then(data => {
          window.location.href = data.redirect_url
        })
    })

    gtag('event', 'click_checkout', { click_type: itemListName, offer_name: offerSlug })
    gtag('event', 'begin_checkout', {
      currency: 'USD',
      value: price,
      items: [{
        item_name: itemName,
        price: price,
        item_list_name: itemListName,
        item_brand: itemBrand,
        item_category: itemCategory,
        item_category2: itemCategory2,
        quantity: 1
      }]
    })
    gtag('event', 'add_to_cart', {
      currency: 'USD',
      value: price,
      items: [{
        item_id: stripePlanId,
        item_name: itemName,
        item_list_name: itemListName,
        price: price,
        quantity: 1
      }]
    })
  }

  getGAClientAndSessionId() {
    return new Promise((resolve) => {
      gtag('get', 'G-NZZK3F4VQS', 'client_id', (client_id) => {
        const session_id = window.dataLayer[0]["gtm.start"]
        resolve({ client_id, session_id })
      })
    })
  }
}