import { Controller } from "stimulus";

export default class extends Controller {

  close() {
    this.element.classList.add("hidden");
    this.element.dataset.active = false;
  }

  appear() {
    if (window.scrollY > 1000 && this.element.dataset.active === "true") {
      this.element.classList.remove("hidden");
    }
  }
}