import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["modal"];

    activateButton() {
        let popupwindowController = this.application.getControllerForElementAndIdentifier(
            this.modalTarget,
            "popupwindow"
        );
        popupwindowController.open();
    }
}
