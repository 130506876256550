import { Controller } from "stimulus";
import copy from "copy-text-to-clipboard";

export default class extends Controller {
  static targets = ["text"];

  get copyBtn() { return this.element.querySelector('[data-clicked-text]') }

  get copiedBtnContent() { return this.copyBtn?.dataset?.clickedText }

  get inputValue() { return this.element.querySelector("input").value }

  connect() {
    this.copyBtnText = this.copyBtn?.innerHTML;
  }

  copyToClipboard(e) {
    copy(this.inputValue);
    e.currentTarget.style.animation = 'pulse 1.25s cubic-bezier(.4,0,.6,1)';
    if (!this.copyBtn || !this.copiedBtnContent) return;
    this.copyBtn.innerText = this.copiedBtnContent;
    setTimeout(() => this.copyBtn.innerHTML = this.copyBtnText, 3000);
  }

  minimalCopyToClipboard(e) {
    copy(this.textTarget.innerText);
    e.currentTarget.style.animation = 'pulse 0.5s cubic-bezier(.4,0,.6,1)';
  }
}
