import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['overlay']

  open() {
    this.overlayTarget.classList.add('bottom-overlay-visible');
  }

  close(e) {
    if (!e.target.classList.contains('bottom-overlay') && !e.target.classList.contains('bottom-overlay-close')) return;
    this.overlayTarget.classList.remove('bottom-overlay-visible');
  }
}
