import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["modal"]

  submit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.modalTarget.classList.add("modal-visible");
    this.element.submit();
  }
}