import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form']
  static values = { url: String }

  submit() {
    window.Rails.fire(this.formTarget, 'submit')
  }

  result(event) {
    const data = event.detail[0]
    if (data.length > 0) {
      if (document.querySelector("[data-target='offer-list']") !== null) {
        document.querySelector("[data-target='offer-list']").outerHTML = data
      } else {
        document.querySelector("nav").nextElementSibling.outerHTML = data
      }
    }
  }
}
