import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'hex'];

  connect() {
    this.insertHex();
  }

  insertHex(event) {
    this.hexTarget.innerText = this.inputTarget.value.toUpperCase();
  }
}