import { Controller } from "stimulus"
import { csrfToken } from "@rails/ujs"

export default class extends Controller {
  static targets = ["modal", "content"]

  connect() {
    this.loadContent()
  }

  loadContent() {
    fetch(window.location.href, {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken(),
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data.error) {
          throw new Error(data.error);
        }
        this.contentTarget.innerHTML = data.html;
        this.modalTarget.style.display = 'none';
      })
      .catch(error => {
        console.error('Error:', error);
        this.contentTarget.innerHTML = `An error occurred while loading the content: ${error.message}`;
        this.modalTarget.style.display = 'none';
      });
  }
}