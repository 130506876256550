import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["overlay", "offerCard"]

  connect() {
    this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
      root: null,
      threshold: 0.25
    });

    this.observer.observe(this.offerCardTarget);
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        this.overlayTarget.classList.remove("hidden");
      } else {
        this.overlayTarget.classList.add("hidden");
      }
    });
  }

  disconnect() {
    this.observer.disconnect();
  }
}
