import { Controller } from "stimulus";
import lightgallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';

export default class extends Controller {
  connect() {
    lightgallery(this.element, {
      speed: 500,
      plugins: [lgThumbnail, lgZoom, lgVideo],
      enableDrag: false,
      enableSwipe: false
    });
  }
}