import { Controller } from "stimulus";
import { useIntersection } from 'stimulus-use';
import { WatchSticky } from 'sticky-change';

export default class extends Controller {
  static targets = ["tabLink", "tabContent", "tabsBar"];

  get navBar() {
    return document.querySelector("nav");
  }

  connect() {
    this.tabContentTargets.forEach(tabContent => {
      useIntersection(this, {
        element: tabContent,
        rootMargin: "-15% 0% -35% 0%",
        threshold: 0.75
      });
    });
    this.extendTabsBarWhenSticky();
  }

  appear(e) {
    this.tabLinkTargets.forEach(tabLink => {
      this.removeActiveTab(tabLink);
      if (tabLink.dataset.content === e.target.dataset.content) {
        this.addActiveTab(tabLink);
      }
    });
  }

  showContent(e) {
    this.changeActiveTab(e);
    this.scrollToTabContent(e);
  }

  changeActiveTab(e) {
    this.tabLinkTargets.forEach(tabLink => {
      this.removeActiveTab(tabLink);
    });
    this.addActiveTab(e.currentTarget);
  }

  scrollToTabContent(e) {
    this.tabContentTargets.forEach(tabContent => {
      if (e.currentTarget.dataset.content === tabContent.dataset.content) {
        window.scrollTo({
          top: this.offset(tabContent).top,
          behavior: "smooth"
        });
      }
    });
  }

  offset(el) {
    const rect = el.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const navBarHeight = this.navBar.offsetHeight;
    return { top: rect.top + scrollTop - (navBarHeight + 48) };
  }

  removeActiveTab(el) {
    el.classList.remove("active");
    el.classList.add("nav");
  }

  addActiveTab(el) {
    el.classList.remove("nav");
    el.classList.add("active");
  }

  extendTabsBarWhenSticky() {
    new WatchSticky(
      this.tabsBarTarget,
      null,
      () => {
        const klasses = "left-0 -ml-25vw -mr-50vw w-screen pl-1/22 lg:pl-7/41 theme-dark-soft z-20"
        klasses.split(" ").forEach((klass) => {
          if (this.hasTabsBarTarget) {
            this.tabsBarTarget.classList.add(klass);
          }
        });
      },
      () => {
        const klasses = "left-0 -ml-25vw -mr-50vw w-screen pl-1/22 lg:pl-7/41 theme-dark-soft z-20"
        klasses.split(" ").forEach((klass) => {
          if (this.hasTabsBarTarget) {
            this.tabsBarTarget.classList.remove(klass);
          }
        });
      }
    )
  }
}
