import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modalOverlay", "form"];

  connect() {
    this.form = this.formTarget.cloneNode(true);
  }

  reset(event) {
    event.preventDefault();
    this.modalOverlayTarget.click();
    event.currentTarget.submit();
    this.formTarget.replaceWith(this.form);
    this.connect();
  }
}
