import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tabLink", "tabContent"];

  showContent(e) {
    e.preventDefault();
    const content = e.currentTarget.dataset.content;

    this.tabContentTargets.forEach(tabContent => {
      if (tabContent.dataset.content === content) {
        tabContent.classList.remove("hidden");
      } else {
        tabContent.classList.add("hidden");
      }
    });

    this.tabLinkTargets.forEach(tabLink => {
      tabLink.classList.remove("active");
      tabLink.classList.add("nav");
    });

    e.currentTarget.classList.remove("nav");
    e.currentTarget.classList.add("active");
  }
}
