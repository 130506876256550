import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    if (this.isPreview) return;
    window.setTimeout(() => {
      this.element.classList.add(this.className);
    }, this.delay);
  }

  disconnect() {
    this.element.classList.remove(this.className);
  }

  get className() {
    return this.data.has("className") ? this.data.get("className") : "";
  }

  get delay() {
    return this.data.has("delay") ? parseInt(this.data.get("delay")) : 100;
  }

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbolinks-preview");
  }
}
