import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["countdownContainer"];

  connect() {
    if (this.countdownContainerTarget) {
      this.countdown();
    }
  }

  countdown() {
    setInterval(() => {
      const d = new Date();
      // let hours = 24 - d.getHours();
      // if ((hours + '').length == 1) {
      //   hours = '0' + hours;
      // }
      // let min = 59 - d.getMinutes();
      // if ((min + '').length == 1) {
      //   min = '0' + min;
      // }
      // let sec = 59 - d.getSeconds();
      // if ((sec + '').length == 1) {
      //   sec = '0' + sec;
      // }
      // this.countdownContainerTarget.innerHTML = hours + ':' + min + ':' + sec


      const end_date = new Date("11-30-2022".replace(/-/g, "/"));
      // get total seconds between the times
      let delta = Math.abs(end_date - d) / 1000;
      // calculate (and subtract) whole days
      let days = Math.floor(delta / 86400);
      delta -= days * 86400;
      // calculate (and subtract) whole hours
      let hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      // calculate (and subtract) whole minutes
      let min = Math.floor(delta / 60) % 60;
      delta -= min * 60;
      // what's left is seconds
      let sec = Math.floor(delta % 60);  // in theory the modulus is not required
      this.countdownContainerTarget.innerHTML = days + this.element.dataset.days + " " + hours + ':' + min + ':' + sec
    }, 1000);
  }

  close() {
    this.element.remove();
  }
}
