import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['userContentInput', 'userTitleInput', 'rating'];

  connect() {
    this.initializeCharacterCount();
  }

  handleError(event) {
    const [data] = event.detail;
    this.renderErrors(data.errors);
  }

  renderErrors(errors) {
    const targetElements = this.element.querySelectorAll('[data-target^="review-form."]');
    this.clearErrors(targetElements);

    Object.entries(errors).forEach(([key, message]) => {
      const target = this[`${key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())}Target`];
      if (target) {
        this.addErrorStyles(target);
        this.createAndAppendErrorMessage(target, message);
      }
    });
  }

  clearErrors(targetElements) {
    targetElements.forEach(target => {
      const formUnit = target.closest('.form-unit');
      this.removeErrorStyles(formUnit);
      this.removeErrorMessages(formUnit);
    });
  }

  addErrorStyles(target) {
    const formUnit = target.closest('.form-unit');
    formUnit.querySelector('.se-label').classList.add('text-danger');
    formUnit.querySelector('.se-input').classList.add('border-danger');
  }

  createAndAppendErrorMessage(target, message) {
    const formUnit = target.closest('.form-unit');
    const errorMessage = document.createElement('div');
    errorMessage.className = 'text-danger text-xs -mt-4';
    errorMessage.innerHTML = message;
    formUnit.appendChild(errorMessage);
  }

  removeErrorStyles(formUnit) {
    formUnit.querySelector('.se-label').classList.remove('text-danger');
    formUnit.querySelector('.se-input').classList.remove('border-danger');
  }

  removeErrorMessages(formUnit) {
    formUnit.querySelectorAll('.text-danger').forEach(element => element.remove());
  }

  initializeCharacterCount() {
    this.userContentInputTargets.forEach(item => {
      const input = item.querySelector('textarea');
      this.createCounter(input, 200);
    });

    this.userTitleInputTargets.forEach(item => {
      const input = item.querySelector('input[type="text"]');
      this.createCounter(input, 10);
    });
  }

  createCounter(input, min) {
    if (isNaN(min)) return;

    const counter = document.createElement('span');
    counter.className = 'absolute right-0 text-sm';
    counter.style.bottom = '-0.25rem'

    input.parentNode.appendChild(counter);

    input.addEventListener('input', () => {
      const inputLength = input.value.length;
      counter.textContent = `${inputLength}/${min} (min)`;

      if (inputLength < min) {
        counter.style.color = 'rgb(239 68 68)';
      } else {
        counter.style.color = 'rgb(74 222 128)';
      }
    });
  }
}
