import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {

  call(event) {
    event.preventDefault();
    event.stopPropagation();
    const method = event.currentTarget.dataset.method;
    const path = event.currentTarget.dataset.path;
    fetch(path, {
      method: method,
      headers: {
        'X-CSRF-Token': csrfToken(),
        'accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then((data) => {
        this.element.outerHTML = data.html;
      });
  }
}
