import { Controller } from "stimulus";
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {
  connect() {
    this.lazyload = new LazyLoad({
      // container: this.element
      // to_webp: true
    });
  }
}
