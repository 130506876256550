import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["details", "image"]

  connect() {
    const initiallyOpenDetails = this.detailsTargets.find(details => details.open);
    if (initiallyOpenDetails) {
      const index = this.detailsTargets.indexOf(initiallyOpenDetails);
      this.showImage(index);
    }
  }

  toggle(event) {
    const clickedDetails = event.currentTarget;

    this.detailsTargets.forEach(details => {
      if (details !== clickedDetails) {
        details.open = false;
      }
    });

    const index = this.detailsTargets.indexOf(clickedDetails);
    this.showImage(index);
  }

  showImage(index) {
    this.imageTargets.forEach((image, i) => {
      if (i === index) {
        image.classList.remove("hidden");
      } else {
        image.classList.add("hidden");
      }
    });
  }
}
