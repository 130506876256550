import { Controller } from "stimulus";

let direction;
let raf;
let running = false;

export default class extends Controller {
  static targets = ['slider', 'previousBtn', 'nextBtn', 'subMenuContainer']

  connect() {
    this.showControls(this.sliderTarget.parentNode.offsetWidth - this.sliderTarget.offsetWidth > 0)
  }

  showControls(boolean) {
    if (boolean) {
      this.previousBtnTarget.classList.add("hidden")
      this.nextBtnTarget.classList.add("hidden")
    } else {
      this.previousBtnTarget.classList.remove("hidden")
      this.nextBtnTarget.classList.remove("hidden")
    }
  }

  setRunning(boolean) {
    running = boolean
  }


  slide() {
    const left = this.sliderTarget.offsetLeft;
    const min = - 1 * (this.sliderTarget.offsetWidth - this.sliderTarget.parentNode.offsetWidth)
    const pos = Math.max(min, Math.min(0, left + direction * 14))
    this.sliderTarget.style.left = `${pos}px`;
    if (running) {
      raf = window.requestAnimationFrame(this.slide.bind(this))
    }
  }

  start(e) {
    this.stop()
    direction = Number.parseInt(e.currentTarget.dataset.direction)
    this.setRunning(true)
    raf = window.requestAnimationFrame(this.slide.bind(this))
  }

  stop(e) {
    this.setRunning(false)
    window.cancelAnimationFrame(raf)
  }

  expand(e) {
    const menuItem = e.currentTarget;
    const subMenu = menuItem.nextElementSibling;
    const menuItemCoords = menuItem.getBoundingClientRect();
    const windowMiddleX = window.innerWidth / 2;

    this.shrink();

    if (subMenu.getElementsByTagName('li').length >= 1) {
      const subMenuCoords = subMenu.getBoundingClientRect();
      subMenu.style.left =
        windowMiddleX > menuItemCoords.left ?
          `${menuItemCoords.left}px` :
          `${menuItemCoords.left - (subMenuCoords.width - menuItemCoords.width)}px`;
      this.subMenuContainerTarget.innerHTML = subMenu.outerHTML;
    }
  }

  shrink() {
    this.subMenuContainerTarget.innerHTML = "";
  }
}
