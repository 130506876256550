import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["offer", "seoDescription", "mobileCtaLabel", "mobileCtaBtn", "offerTab", "headerOfferCta"];

  connect() {
    this.element[this.identifier] = this;
    if (window.location.hash.split('?')[0].slice(1).length !== 0) {
      this.selectedOfferSlug = window.location.hash.split('?')[0].slice(1);
    } else {
      this.selectedOfferSlug = this.element.dataset.defaultOfferSlug;
    }
    setTimeout(() => this.refresh(true), 0.01);
  }

  get offerTargetsControllers() {
    return this.offerTargets.map((offerTarget) => this.application.getControllerForElementAndIdentifier(offerTarget, 'products--offer'));
  }

  get selectedOfferControllers() {
    return this.offerTargetsControllers.filter(contr => contr.offerSlug == this.selectedOfferSlug)
  }

  get rightOffersCards() {
    const rightOffersCards = document.querySelectorAll("[data-right-offer-slug]")
    if (rightOffersCards.length === 0) return;

    return rightOffersCards
  }

  // Gère toutes les modifications du DOM en fonction du deal sélectionné. Appelée :
  // -> dans le connect pour prendre en compte le deal figurant dans l'url (le cas échéant) ou le deal par défaut (définit au niveau du component)
  // -> au niveau de l'action offer:change (sur les offer_components)
  refresh(pageLoad = false) {
    if (event && event.detail.offerSlug) this.selectedOfferSlug = event.detail.offerSlug;
    this.offerTargetsControllers.forEach(contr => contr.unselect());
    this.selectedOfferControllers.forEach(contr => contr.select());
    // this.#showSelectedOfferSeoDescription();
    this.#activateOfferTab();
    this.#changeMobileCtaLabel();
    this.#changeMobileCtaBtn();
    this.#changeRightOfferCard(pageLoad);
    this.#showSelectedOfferHeaderOfferCta();
  }

  switch(event) {
    event.preventDefault();
    this.selectedOfferSlug = event.currentTarget.dataset.offerSlug;
    this.refresh();
  }

  #showSelectedOfferHeaderOfferCta() {
    this.headerOfferCtaTargets.forEach(el => el.classList.add('hidden'));
    const headerOfferCta = this.headerOfferCtaTargets.find(el => el.dataset.offerSlug == this.selectedOfferSlug)
    if (!headerOfferCta) return;

    headerOfferCta.classList.remove('hidden');
  }

  #activateOfferTab() {
    this.offerTabTargets.forEach(el => this.#deActivateSelectedOfferTab(el))
    const tab = this.offerTabTargets.find(target => target.dataset.offerSlug == this.selectedOfferSlug);
    if (!tab) return;

    this.#activateSelectedOfferTab(tab);
  }

  #deActivateSelectedOfferTab(tabTarget) {
    tabTarget.classList.remove("bg-gray-900");
    tabTarget.classList.remove("text-white");
    tabTarget.classList.add("text-gray-900");
  }

  #activateSelectedOfferTab(tabTarget) {
    tabTarget.classList.add("bg-gray-900");
    tabTarget.classList.add("text-white");
    tabTarget.classList.remove("text-gray-900");
  }

  #hideAllSeoDescriptionTargets() {
    this.seoDescriptionTargets.forEach((el) => el.classList.add("hidden"));
  }

  #showSelectedOfferSeoDescription() {
    if (this.seoDescriptionTargets.length !== 0) {
      this.#hideAllSeoDescriptionTargets();
      const selectedOfferSeoDescription = this.seoDescriptionTargets.filter((el) => el.dataset.offerSlug === this.selectedOfferSlug);
      selectedOfferSeoDescription.forEach(el => el.classList.remove("hidden"));
    }
  }

  #changeRightOfferCard(pageLoad = false) {
    if (!this.rightOffersCards) return;

    this.rightOffersCards.forEach((offer) => {
      offer.classList.add("hidden");
      if (offer.dataset.rightOfferSlug == this.selectedOfferSlug) {
        offer.classList.remove("hidden");
        if (pageLoad) return;
      }
    });
  }

  #changeMobileCtaLabel() {
    if (!this.hasMobileCtaLabelTarget) return;
    if (!this.selectedOfferControllers[0]) return;

    this.mobileCtaLabelTarget.innerText = this.selectedOfferControllers[0].offerDescription;
  }

  #changeMobileCtaBtn() {
    if (!this.hasMobileCtaBtnTarget) return;
    this.mobileCtaBtnTargets.forEach((btn) => {
      btn.classList.add("hidden");
      if (btn.dataset.offerSlug === this.selectedOfferSlug) btn.classList.remove("hidden");
    });
  }
}