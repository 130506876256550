import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "overlay", "panelContainer"];

  open(e) {
    this.containerTarget.innerText = "";
    this.containerTarget.innerText = e.currentTarget.dataset.infoText;
    this.containerTarget.classList.remove("hidden");
    this.containerTarget.classList.add("absolute");
  }

  close() {
    this.containerTarget.classList.remove("absolute");
    this.containerTarget.classList.add("hidden");
  }
}