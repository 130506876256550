import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'form', 'result', 'reset', 'filtering', 'productInput', 'countryInput', 'statusInput', 'nameInput', 'onlyPartnersInput', 'onlyPartnersLabel', 'onlyPartnersSpan'
  ]
  static values = { url: String }

  connect() {
    const nameInputTargetSelected =
      this.nameInputTarget.value !== ''
    const productInputSelected =
      this.productInputTarget.value !== ''
    const countryInputSelected =
      this.countryInputTarget.options[this.countryInputTarget.selectedIndex].value !== ''
    const statusInputSelected =
      this.statusInputTarget.value !== ''
    const onlyPartnersInputSelected =
      this.onlyPartnersInputTarget.checked

    if (productInputSelected || countryInputSelected || statusInputSelected || nameInputTargetSelected || onlyPartnersInputSelected) {
      this.displayResetButton()
    }
  }

  toggleOnlyPartners() {
    const onlyPartnersInputSelected =
      this.onlyPartnersInputTarget.checked
    if (onlyPartnersInputSelected) {
      this.onlyPartnersLabelTarget.classList.remove('bg-gray-200')
      this.onlyPartnersLabelTarget.classList.add('bg-success')
      this.onlyPartnersSpanTarget.classList.remove('translate-x-0')
      this.onlyPartnersSpanTarget.classList.add('translate-x-5')
    } else {
      this.onlyPartnersLabelTarget.classList.remove('bg-success')
      this.onlyPartnersLabelTarget.classList.add('bg-gray-200')
      this.onlyPartnersSpanTarget.classList.remove('translate-x-5')
      this.onlyPartnersSpanTarget.classList.add('translate-x-0')
    }
  }

  displayResetButton() {
    this.resetTarget.classList.remove('hidden')
  }

  submit() {
    this.toggleOnlyPartners()
    window.Rails.fire(this.formTarget, 'submit')
  }

  result(event) {
    const data = event.detail[0]
    if (data.length > 0) {
      this.resultTarget.innerHTML = data
      this.resetTarget.classList.toggle('hidden', this.filteringTarget.dataset.filtering != 'true')
    }
  }
}
