// import { tns } from "tiny-slider/src/tiny-slider";
import { Controller } from "stimulus";

const DEFAULT_WIDTH = 300;
const DEFAULT_HEIGHT = 84;
const ACTIVE_WIDTH = 342;
const ACTIVE_HEIGHT = 90;

export default class extends Controller {
  slider;

  callback = info => {
    const slides = [...info.slideItems];
    let activeCount = 0;

    slides.forEach(slide => {
      const isActive = slide.classList.contains("tns-slide-active");
      if (isActive) {
        activeCount += 1;

        if (activeCount === 1 || activeCount === 5) {
          slide.querySelector(".c-carousel-offer").style.opacity = 0.2;
          slide.querySelector(
            ".c-carousel-offer"
          ).style.width = `${DEFAULT_WIDTH}px`;
          slide.querySelector(
            ".c-carousel-offer"
          ).style.height = `${DEFAULT_HEIGHT}px`;
        } else if (activeCount === 2 || activeCount === 4) {
          slide.querySelector(".c-carousel-offer").style.opacity = 0.6;
          slide.querySelector(
            ".c-carousel-offer"
          ).style.width = `${DEFAULT_WIDTH}px`;
          slide.querySelector(
            ".c-carousel-offer"
          ).style.height = `${DEFAULT_HEIGHT}px`;
        } else if (activeCount === 3) {
          slide.querySelector(".c-carousel-offer").style.opacity = 1;
          slide.querySelector(
            ".c-carousel-offer"
          ).style.width = `${ACTIVE_WIDTH}px`;
          slide.querySelector(
            ".c-carousel-offer"
          ).style.height = `${ACTIVE_HEIGHT}px`;
        }
      } else {
        slide.querySelector(".c-carousel-offer").style.opacity = 0.2;
        slide.querySelector(
          ".c-carousel-offer"
        ).style.width = `${DEFAULT_WIDTH}px`;
        slide.querySelector(
          ".c-carousel-offer"
        ).style.height = `${DEFAULT_HEIGHT}px`;
      }
    });
  };

  connect() {
    if (this.isPreview) return;

    import("tiny-slider/src/tiny-slider").then(({ tns }) => {
      this.slider = tns({
        container: ".c-carousel-offers",
        axis: "vertical",
        controls: false,
        nav: false,
        autoHeight: false,
        items: 5,
        gutter: 12,
        autoplay: true,
        autoplayTimeout: 3700,
        autoplayHoverPause: false,
        preventActionWhenRunning: true,
        onInit: this.init
      });

      this.slider.events.on("transitionStart", this.callback);
      this.slider.events.on("transitionEnd", this.callback);
    });
  }

  init = info => {
    this.element.classList.add("c-home-hero-cards--visible");
    this.callback(info);
  };

  disconnect() {
    this.slider && this.slider.destroy();
    this.element.style.display = "none";
  }

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbolinks-preview");
  }
}
