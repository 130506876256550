import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["actions"]

  connect() {
    this.element[this.identifier] = this;
    this.currentPath = this.element.dataset.currentPath;
  }

  refresh() {
    fetch(this.currentPath, {
      method: 'GET',
      headers: {
        'accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        this.element.outerHTML = data.html;
        console.log("Company members table refreshed")
      });
  }
}
