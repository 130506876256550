import { Controller } from "stimulus";

const OFFSET_TOP = 80;
const FOOTER_MARGIN = 42;

export default class extends Controller {
  handleScroll = () => {
    const categories = this.element.querySelector(".l-categories");
    const badges = this.element.querySelector(".badges-legend");

    if (!categories) return;

    const { pageYOffset } = window;
    const { offsetTop } = this.element;

    const topScroll = pageYOffset + OFFSET_TOP;

    if (topScroll > offsetTop) {
      this.element.classList.add("l-container--fixed-categories");
      if (badges) { badges.classList.add("badges-legend--fixed"); }
    } else {
      this.element.classList.remove("l-container--fixed-categories");
      if (badges) { badges.classList.remove("badges-legend--fixed"); }
    }

    const footerOffset = document.querySelector("footer").offsetTop;
    const maxBottom = FOOTER_MARGIN + topScroll + categories.offsetHeight;
    const overflow = footerOffset - maxBottom;

    // if (maxBottom > footerOffset) {
    //   categories.style.transform = `translateY(${overflow}px)`;
    // } else {
    //   categories.style.transform = '';
    // }
  };

  connect() {
    this.handleScroll();
    document.addEventListener("mousewheel", this.handleScroll);
  }

  disconnect() {
    document.removeEventListener("mousewheel", this.handleScroll);
  }
}
