import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    setTimeout(this.updateStatus, 1000);
  }

  updateStatus = () => {
    fetch(this.path, {
      headers: { accept: "application/json" }
    })
      .then(response => response.json())
      .then(data => {
        if (data.path) {
          window.location.href = data.path;
        } else {
          setTimeout(this.updateStatus, 1000);
        }
      });
  };

  get path() {
    return this.data.has("path") && this.data.get("path");
  }
}
