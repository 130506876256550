import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;
  }

  refresh(html) {
    this.element.outerHTML = html;
    // fetch(this.currentPath, {
    //   method: 'GET',
    //   headers: {
    //     'accept': 'application/json'
    //   }
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     this.element.outerHTML = data.html;
    //     console.log("Companies table refreshed")
    //   });
  }
}
