import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['submit', 'input']

  connect() {
    this.checkLockingSubmit();
  }

  validate(e) {
    const klasses = "ring-1 ring-offset-1 focus:ring-0".split(" ");
    klasses.forEach((klass) => {
      e.currentTarget.classList.add(klass);
    });
    this.removeValidationMessage(e.currentTarget);
    if (e.currentTarget.value) {
      e.currentTarget.classList.remove("ring-danger");
      e.currentTarget.classList.add("ring-success");
    } else {
      e.currentTarget.classList.remove("ring-success");
      e.currentTarget.classList.add("ring-danger");
      const message = e.currentTarget.dataset.messageValidation;
      this.insertValidationMessage(e.currentTarget, message)
    }
    this.checkLockingSubmit();
  }

  checkLockingSubmit() {
    const inputValues = Array.from(this.inputTargets).map(input => input.value);
    if (inputValues.includes("")) {
      this.submitTarget.disabled = true;
      this.submitTarget.classList.add("cursor-not-allowed");
    } else {
      this.submitTarget.disabled = false;
      this.submitTarget.classList.remove("cursor-not-allowed");
    }
  }

  insertValidationMessage(input, message) {
    const html = `<span class="flex items-center text-danger text-xs -mt-3 ml-1">
                    ${message}
                  </span>`
    const node = document.createRange().createContextualFragment(html);
    this.insertAfter(input, node)
  }

  removeValidationMessage(input) {
    const nextElement = input.nextElementSibling;
    if (nextElement && nextElement.tagName === "SPAN") {
      nextElement.remove();
    }
  }

  insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
}
