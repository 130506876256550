import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"]

  toggleDeals() {
    const url = new URL(window.location.href);

    if (this.checkboxTarget.checked) {
      url.searchParams.set("with_deals", "true");
    } else {
      url.searchParams.delete("with_deals");
    }

    window.location.href = url.toString();
  }
}
