import { Controller } from "stimulus";
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ["selectedBtn", "unSelectedBtn"]

  connect() {
    useDispatch(this);
    this.element[this.identifier] = this;
  }

  get offerDescription() {
    return this.element.dataset.offerDescription;
  }

  get offerSlug() {
    return this.element.dataset.offerSlug;
  }

  choose() {
    this.dispatch('change', { offerSlug: this.element.dataset.offerSlug })
  }

  select() {
    this.element.classList.add("border-mint");
    this.element.classList.remove("lg:hidden");
    this.selectedBtnTarget.classList.remove("hidden");
    this.unSelectedBtnTarget.classList.add("hidden");
  }

  unselect() {
    this.element.classList.remove("border-mint");
    this.element.classList.add("lg:hidden");
    this.selectedBtnTarget.classList.add("hidden");
    this.unSelectedBtnTarget.classList.remove("hidden");
  }
}