import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'img'];

  preview(event) {
    let file = event.target.files[0];
    let reader = new FileReader();

    reader.onload = (e) => {
      this.imgTarget.src = e.target.result;
    }
    reader.readAsDataURL(file);
  }
}
