// import { tns } from "tiny-slider/src/tiny-slider";
import { Controller } from "stimulus";

export default class extends Controller {
  slider;

  connect() {
    import("tiny-slider/src/tiny-slider").then(({ tns }) => {
      this.slider = tns({
        container: ".c-carousel-testimonials",
        center: true,
        controls: true,
        autoHeight: true,
        navPosition: "bottom",
        autoplay: true
      });
    });
  }

  disconnect() {
    this.slider.destroy();
  }
}
