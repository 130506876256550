import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["more", "moreBtn", "lessBtn"];

  more(e) {
    e.preventDefault();
    if (this.hasMoreTarget) {
      this.moreTargets.forEach(element => {
        element.classList.remove("hidden");
      });
    }
    if (this.hasMoreBtnTarget) {
      this.moreBtnTarget.classList.add("hidden");
    }
    if (this.hasLessBtnTarget) {
      this.lessBtnTarget.classList.remove("hidden");
    }
  }

  less(e) {
    e.preventDefault();
    if (this.hasMoreTarget) {
      this.moreTargets.forEach(element => {
        element.classList.add("hidden");
      });
    }
    if (this.hasMoreBtnTarget) {
      this.moreBtnTarget.classList.remove("hidden");
    }
    if (this.hasLessBtnTarget) {
      this.lessBtnTarget.classList.add("hidden");
    }
  }
}
