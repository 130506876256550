import { Controller } from "stimulus";

const OFFSET_TOP = 120;

export default class extends Controller {
  scrollIntoView(e) {
    const faqId = e.currentTarget.dataset.faqId;
    const element = document.getElementById(`faq-question-${faqId}`);
    const y = element.getBoundingClientRect().top + window.pageYOffset - OFFSET_TOP;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
