import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "menu", "overlay"];

  open() {
    this.menuTarget.classList.remove("hidden");
    this.overlayTarget.classList.remove("hidden");
  }

  close() {
    this.menuTarget.classList.add("hidden");
    this.overlayTarget.classList.add("hidden");
  }
}