import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["actions"]

  connect() {
    this.bgClass = Array.from(this.element.classList).find(c => c.match(/bg-gray/))
    this.destroyConfirmText = this.element.dataset.destroyConfirmText;
  }

  get availableCreditsComponentControllers() {
    const identifier = 'programs--available-credits-component';
    const elements = Array.from(document.querySelectorAll(`[data-controller*='${identifier}']`));
    if (elements.length == 0) return;

    return elements.map(el => this.application.getControllerForElementAndIdentifier(el, identifier));
  }

  call(event) {
    event.preventDefault();
    event.stopPropagation();
    const method = event.currentTarget.dataset.method;
    if (method == "delete" && !confirm(this.destroyConfirmText)) return;
    fetch(event.currentTarget.href, {
      method: event.currentTarget.dataset.method,
      headers: {
        'X-CSRF-Token': csrfToken(),
        'accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then((data) => {
        if (method == "delete") {
          if (!data.success) return;
          this.element.classList.add('line-through');
          this.element.classList.add('bg-gray-200');
          this.actionsTarget.remove();
        } else {
          this.element.outerHTML = data.html;
          this.bgClass ? this.element.classList.add(this.bgClass) : this.element.classList.remove(this.bgClass)
          // on refresh le compteur des crédits dispo
          if (data.available_credit_html) {
            this.availableCreditsComponentControllers.forEach(controller => controller.refresh(data.available_credit_html))
          }
          // on refresh le bouton "Add company" qui devient "Buy credits" s'il n'y a plus de crédits
          if (data.buy_credit_html) {
            const element = document.getElementById('add-client-or-buy-credit');
            if (!element) return;
            element.innerHTML = data.buy_credit_html;
          }
        }
      });
  }
}
