import { Controller } from "stimulus"

const HIDE_EMAIL_BANNER = 'hide-email-banner'

export default class extends Controller {
  handleClickClose() {
    sessionStorage.setItem(HIDE_EMAIL_BANNER, 1);
    this.close();
  }

  connect() {
    if (sessionStorage.getItem(HIDE_EMAIL_BANNER)) {
      this.close();
    } else {
      document.body.classList.add('l-email-banner--visible');
      document.body.classList.remove('l-email-banner--hidden');
    }
  }

  close() {
    this.element.classList.add('c-banner--hidden');
    document.body.classList.add('l-email-banner--hidden');
    document.body.classList.remove('l-email-banner--visible');
  }
}
