import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["link"]
  openLink(event) {
    event.preventDefault();

    if (this.linkTarget.dataset.description) {
      gtag("event", "click_sign_up", {
        click_type: "offer_description",
        from_offer_slug: this.linkTarget.dataset.offerSlug
      });
    } else {
      gtag("event", "click_sign_up", {
        click_type: "offer_card",
        from_offer_slug: this.linkTarget.dataset.offerSlug
      });
    }
    window.open(decodeURIComponent(window.atob(this.linkTarget.dataset.qcd)), "_self");
  }
}
