import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ['label']

  get heartContainers() {
    return document.querySelectorAll('[data-name="heartContainer"]');
  }

  async create(e) {
    try {
      const currentHeartContainer = e.currentTarget;
      const offerId = currentHeartContainer.dataset.offerId;
      const response = await fetch(`/favorites`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ offer_id: offerId })
      })
      const data = await response.json();
      if (data.success) {
        this.toggleHeart(currentHeartContainer, "destroy", data);
        this.heartContainers.forEach((container) => {
          if (container.dataset.offerId == offerId) {
            this.toggleHeart(container, "destroy", data);
          }
        });
        if (this.hasLabelTarget) {
          this.labelTarget.innerText = this.element.dataset.removeLabel;
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  async destroy(e) {
    try {
      const currentHeartContainer = e.currentTarget;
      const offerId = currentHeartContainer.dataset.offerId;
      const favoriteId = currentHeartContainer.dataset.favoriteId;
      if (favoriteId) {
        const response = await fetch(`/favorites/${favoriteId}`, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': csrfToken(),
            'Content-Type': 'application/json'
          }
        })
        const data = await response.json();
        if (data.success) {
          this.toggleHeart(currentHeartContainer, "create", data);
          this.heartContainers.forEach((container) => {
            if (container.dataset.offerId == offerId) {
              this.toggleHeart(container, "create", data);
            }
          });
          if (this.hasLabelTarget) {
            this.labelTarget.innerText = this.element.dataset.addLabel;
          }
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  toggleHeart(el, action, data) {
    const destroy = action === "destroy";
    el.dataset.action = `click->favorite#${action}`;
    if (destroy) { el.dataset.favoriteId = data.id };
    el.innerHTML = `<i class="fa-heart ${destroy ? "fas text-pink" : "far"}"></i>`;
  }

  clickIcon(e) {
    e.currentTarget.querySelector('[data-name="heartContainer"]').click();
  }
}