import { Controller } from "stimulus";
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { format, subMonths, eachMonthOfInterval } from 'date-fns';
import { en } from 'date-fns/locale';

export default class extends Controller {
  static targets = ["usersBarChart", "productsPieChart", "redeemsBarChart", "savingsBarChart", "creditsScatterChart", "programsLineChart", "usersLineChart"];

  connect() {
    if (this.hasUsersBarChartTarget) { this.usersBarChart() };
    if (this.hasProductsPieChartTarget) { this.productsPieChart() };
    if (this.hasRedeemsBarChartTarget) { this.redeemsBarChart() };
    if (this.hasSavingsBarChartTarget) { this.savingsBarChart() };
    if (this.hasCreditsScatterChartTarget) { this.creditsScatterChart() };
    if (this.hasProgramsLineChartTarget) { this.programsLineChart() };
    if (this.hasUsersLineChartTarget) { this.usersLineChart() };
  }

  usersBarChart() {
    new Chart(this.usersBarChartTarget, {
      type: 'bar',
      data: {
        labels: this.usersBarChartTarget.dataset.months.split(","),
        datasets: [{
          label: "New users",
          data: this.usersBarChartTarget.dataset.users.split(","),
          backgroundColor: [
            'rgba(2, 183, 122, 1)'
          ],
        }]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#888888'
            }
          },
          x: {
            ticks: {
              color: '#888888'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }

  savingsBarChart() {
    new Chart(this.savingsBarChartTarget, {
      type: 'bar',
      data: {
        labels: this.savingsBarChartTarget.dataset.months.split(","),
        datasets: [{
          label: "Savings ($)",
          data: this.savingsBarChartTarget.dataset.savings.split(","),
          backgroundColor: [
            'rgba(2, 183, 122, 1)'
          ],
        }]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#888888'
            }
          },
          x: {
            ticks: {
              color: '#888888'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }

  redeemsBarChart() {
    new Chart(this.redeemsBarChartTarget, {
      type: 'bar',
      data: {
        labels: this.redeemsBarChartTarget.dataset.months.split(","),
        datasets: [{
          label: "Redeems",
          data: this.redeemsBarChartTarget.dataset.redeems.split(","),
          backgroundColor: [
            'rgba(2, 183, 122, 1)'
          ]
        }]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#888888'
            }
          },
          x: {
            ticks: {
              color: '#888888'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }

  productsPieChart() {
    new Chart(this.productsPieChartTarget, {
      type: 'bar',
      data: {
        labels: this.productsPieChartTarget.dataset.productNames.split(","),
        datasets: [{
          label: 'Redeems',
          data: this.productsPieChartTarget.dataset.productRedeems.split(","),
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(50, 50, 150, 1)',
            'rgba(105, 205, 105, 1)',
            'rgba(255, 100, 207, 1)',
            'rgba(225, 225, 225, 1)'
          ],
          hoverOffset: 4
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }

  creditsScatterChart() {
    const rawData = JSON.parse(this.creditsScatterChartTarget.dataset.credits);
    console.log("Raw data:", rawData);

    // Générer les 12 derniers mois
    const endDate = new Date();
    const startDate = subMonths(endDate, 11);
    const last12Months = eachMonthOfInterval({ start: startDate, end: endDate });

    // Préparer les données
    const formattedData = last12Months.map(month => {
      const monthData = rawData.find(d => {
        const dataDate = new Date(d.created_at);
        return dataDate.getMonth() === month.getMonth() && dataDate.getFullYear() === month.getFullYear();
      });
      return {
        x: month.getTime(),
        y: monthData ? monthData.credits : null
      };
    });

    console.log("Formatted data:", formattedData);

    new Chart(this.creditsScatterChartTarget, {
      type: 'scatter',
      data: {
        datasets: [{
          label: 'Credits',
          data: formattedData,
          backgroundColor: 'rgba(75, 192, 192, 1)'
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MMM yyyy'
              }
            },
            min: startDate.getTime(),
            max: endDate.getTime(),
            title: {
              display: true,
              text: 'Mois',
              color: '#888888'
            },
            ticks: {
              source: 'data',
              callback: function (value) {
                return format(new Date(value), 'MMM yyyy', { locale: en });
              },
              color: '#888888',
              maxRotation: 45,
              minRotation: 45
            }
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Credits',
              color: '#888888'
            },
            ticks: {
              color: '#888888'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const date = new Date(context.parsed.x);
                const credits = context.parsed.y;
                return credits !== null
                  ? `Credits: ${credits}`
                  : `Pas de données pour ${format(date, 'MMMM yyyy', { locale: en })}`;
              }
            }
          }
        }
      }
    });
  }

  programsLineChart() {
    if (!this.programsLineChartTarget) {
      console.error('Chart target not found');
      return;
    }

    const rawData = this.programsLineChartTarget.dataset.newPrograms;
    if (!rawData) {
      console.error('No data available for the chart');
      return;
    }

    let data;
    try {
      data = JSON.parse(rawData);
    } catch (error) {
      console.error('Error parsing chart data:', error);
      return;
    }

    if (!Array.isArray(data) || data.length === 0) {
      console.error('Invalid or empty data for the chart');
      return;
    }

    const chartData = {
      labels: data.map(item => item.date),
      datasets: [{
        label: this.programsLineChartTarget.dataset.label,
        data: data.map(item => item.count),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        tension: 0.1,
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 8
      }]
    };

    const config = {
      type: 'line',
      data: chartData,
      options: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MMM yyyy'
              }
            },
            title: {
              display: true,
              text: 'Date'
            }
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.programsLineChartTarget.dataset.labelAbs
            }
          }
        }
      }
    };

    try {
      new Chart(this.programsLineChartTarget, config);
    } catch (error) {
      console.error('Error creating chart:', error);
    }
  }

  usersLineChart() {
    if (!this.usersLineChartTarget) {
      console.error('Chart target not found');
      return;
    }

    const rawData = this.usersLineChartTarget.dataset.newUsers;
    if (!rawData) {
      console.error('No data available for the chart');
      return;
    }

    let data;
    try {
      data = JSON.parse(rawData);
    } catch (error) {
      console.error('Error parsing chart data:', error);
      return;
    }

    if (!Array.isArray(data) || data.length === 0) {
      console.error('Invalid or empty data for the chart');
      return;
    }

    const chartData = {
      labels: data.map(item => item.date),
      datasets: [{
        label: this.usersLineChartTarget.dataset.label,
        data: data.map(item => item.count),
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.1,
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 8
      }]
    };

    const config = {
      type: 'line',
      data: chartData,
      options: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MMM yyyy'
              }
            },
            title: {
              display: true,
              text: 'Date'
            }
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.usersLineChartTarget.dataset.labelAbs
            }
          }
        }
      }
    };

    try {
      new Chart(this.usersLineChartTarget, config);
    } catch (error) {
      console.error('Error creating chart:', error);
    }
  }
}